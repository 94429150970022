// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iI_n0YIfsIlsy8OCC2Tg{border:1px solid var(--py-neutral-2);padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:var(--py-neutral-0);color:#26a977;font-weight:600;line-height:40px}.iI_n0YIfsIlsy8OCC2Tg ._YrzGTSUvH4i3WzfUCyL{position:absolute;top:1px;left:1px;width:38px;height:38px}.iI_n0YIfsIlsy8OCC2Tg ._YrzGTSUvH4i3WzfUCyL img{position:absolute;top:7px;left:7px;width:24px;height:24px}.iI_n0YIfsIlsy8OCC2Tg .z7x0SlQqCg8CP3OY8T5N{margin-left:40px}.iI_n0YIfsIlsy8OCC2Tg:hover{background-color:var(--py-neutral-1);border-color:var(--py-neutral-3)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `iI_n0YIfsIlsy8OCC2Tg`,
	"iconWrapper": `_YrzGTSUvH4i3WzfUCyL`,
	"label": `z7x0SlQqCg8CP3OY8T5N`
};
export default ___CSS_LOADER_EXPORT___;
