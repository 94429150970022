// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3wGhGlPGjgCfHVlL3b7{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:50px;height:40px;background-color:#ed1c2e;color:var(--py-neutral-0);font-weight:600;line-height:40px}.F3wGhGlPGjgCfHVlL3b7 .bJBY2qKuvFJIZsP7AGIT{position:absolute;top:5px;left:20px;width:50px;height:30px;border-right:1px solid rgba(255,255,255,.35)}.F3wGhGlPGjgCfHVlL3b7 .bJBY2qKuvFJIZsP7AGIT img{position:absolute;top:5px;left:0px;height:20px}.F3wGhGlPGjgCfHVlL3b7 .i_bdau3fnfoATJodeN8K{margin-left:80px}.F3wGhGlPGjgCfHVlL3b7:hover{background-color:#f04251}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `F3wGhGlPGjgCfHVlL3b7`,
	"iconWrapper": `bJBY2qKuvFJIZsP7AGIT`,
	"label": `i_bdau3fnfoATJodeN8K`
};
export default ___CSS_LOADER_EXPORT___;
