// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f5Gyeh3qH0aEUMvBLNY9{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#84bf3d;color:var(--py-neutral-0);font-weight:600;line-height:40px}.f5Gyeh3qH0aEUMvBLNY9 .M77goyBQiqdMGx5mnoZ4{position:absolute;top:1px;left:1px;width:38px;height:38px}.f5Gyeh3qH0aEUMvBLNY9 .M77goyBQiqdMGx5mnoZ4 img{position:absolute;top:7px;left:7px;width:24px;height:24px}.f5Gyeh3qH0aEUMvBLNY9 .DfFbw43hwJYsqRWp6JJk{margin-left:40px}.f5Gyeh3qH0aEUMvBLNY9:hover{background-color:#98ca5a}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `f5Gyeh3qH0aEUMvBLNY9`,
	"iconWrapper": `M77goyBQiqdMGx5mnoZ4`,
	"label": `DfFbw43hwJYsqRWp6JJk`
};
export default ___CSS_LOADER_EXPORT___;
