// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g_STkFE_EUr01sncLP1w{margin-top:30px;text-align:center}.g_STkFE_EUr01sncLP1w .WQTDMC16BWxgpL7ICnQF{display:inline-block;padding:5px 5px;vertical-align:middle}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ssoButtons": `g_STkFE_EUr01sncLP1w`,
	"ssoButtonWrapper": `WQTDMC16BWxgpL7ICnQF`
};
export default ___CSS_LOADER_EXPORT___;
