// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y7O7gy_J4n6xEx7XYwJy{margin-top:20px;font-size:1em}.Y7O7gy_J4n6xEx7XYwJy .RVu8jYo2qf93kWuJPV1x .Vg8xHx5kshO8K0EGbZsz{float:left;color:#f1634f}.Y7O7gy_J4n6xEx7XYwJy .RVu8jYo2qf93kWuJPV1x .ZKeiTZXO7x2MvqTiDRHu{color:#f1634f;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Y7O7gy_J4n6xEx7XYwJy`,
	"printError": `RVu8jYo2qf93kWuJPV1x`,
	"icon": `Vg8xHx5kshO8K0EGbZsz`,
	"value": `ZKeiTZXO7x2MvqTiDRHu`
};
export default ___CSS_LOADER_EXPORT___;
