// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ofxP8hjtQ1KbFhugOMUT{border-radius:1000px}.hhE963EIxbC5A12FJ8SO{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block}.VsGs_gAQGlxE9iLUh9jY{display:flex;flex-direction:column;justify-content:center;align-items:center}.YrTIRiZhXW5w2spXswrs{animation:yPb2MMvH3pgwxxuXueRg 3s infinite;animation-iteration-count:infinite}@keyframes yPb2MMvH3pgwxxuXueRg{0%{background:var(--py-neutral-1)}30%{background:var(--py-neutral-1)}50%{background:var(--py-neutral-1)}80%{background:var(--py-neutral-1)}100%{background:var(--py-neutral-1)}}.YlSpqgc4ErXAGL0ZXng9{margin-bottom:50px;text-align:center}.YlSpqgc4ErXAGL0ZXng9 .XuhS0eV8Y6mvEHbvMf8k{display:inline-block;height:70px;width:306px}.YlSpqgc4ErXAGL0ZXng9 .XuhS0eV8Y6mvEHbvMf8k .RtE0aM5qaIX6QHRXRNuU{display:block;position:absolute;top:0;left:0;width:304px;height:40px}.YlSpqgc4ErXAGL0ZXng9 .XuhS0eV8Y6mvEHbvMf8k .RtE0aM5qaIX6QHRXRNuU img{position:absolute;top:0;left:0;height:100%}.YlSpqgc4ErXAGL0ZXng9 .oo0wWmHxJXtFsgEcd0gy{width:100%;text-align:center;font-weight:600;color:#3e90c1}.YlSpqgc4ErXAGL0ZXng9 .JZE3_twWc7XmGkWhXdke{margin-top:30px;font-size:2em;color:#f1634f}.YlSpqgc4ErXAGL0ZXng9 .z_tbEgJuUp1n7zdC2Xe8{margin:10px auto 0 auto;width:500px;line-height:1.3em;color:var(--py-neutral-8)}.YlSpqgc4ErXAGL0ZXng9 .z_tbEgJuUp1n7zdC2Xe8 a{color:#3e90c1}.YlSpqgc4ErXAGL0ZXng9 .VjfwUKiYFkdF11cieGpo{margin-top:40px}.YlSpqgc4ErXAGL0ZXng9 .HuOmI2quSMA4rkbz76dE{margin:20px auto 0 auto;width:500px;font-size:.85em;font-style:italic;font-weight:500;line-height:1.3em}.YlSpqgc4ErXAGL0ZXng9 .HuOmI2quSMA4rkbz76dE span{white-space:nowrap}.FaGgxQXmqx9Y9jufEwtL{min-height:100vh;z-index:10;background-color:var(--py-neutral-0);display:flex;flex-direction:column;justify-content:center;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `ofxP8hjtQ1KbFhugOMUT`,
	"text-truncate": `hhE963EIxbC5A12FJ8SO`,
	"shaquille": `VsGs_gAQGlxE9iLUh9jY`,
	"shimmer": `YrTIRiZhXW5w2spXswrs`,
	"placeHolderShimmer": `yPb2MMvH3pgwxxuXueRg`,
	"logoHeader": `YlSpqgc4ErXAGL0ZXng9`,
	"logoWrapper": `XuhS0eV8Y6mvEHbvMf8k`,
	"performYardLogo": `RtE0aM5qaIX6QHRXRNuU`,
	"signinCTA": `oo0wWmHxJXtFsgEcd0gy`,
	"errorIcon": `JZE3_twWc7XmGkWhXdke`,
	"invalidMessage": `z_tbEgJuUp1n7zdC2Xe8`,
	"loginButtonWrapper": `VjfwUKiYFkdF11cieGpo`,
	"contactInfo": `HuOmI2quSMA4rkbz76dE`,
	"mainContent": `FaGgxQXmqx9Y9jufEwtL`
};
export default ___CSS_LOADER_EXPORT___;
