// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GENMm878UOuRyfu6rW5Z{margin-top:20px;font-size:1em}.GENMm878UOuRyfu6rW5Z .X7Q7FSzDIuefXNm4Yx1M .IJPCC5DSecyS5rOsVD77{float:left;color:#f1634f}.GENMm878UOuRyfu6rW5Z .X7Q7FSzDIuefXNm4Yx1M .SuUk8RDkVHsjYj6FazZq{color:#f1634f;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `GENMm878UOuRyfu6rW5Z`,
	"printError": `X7Q7FSzDIuefXNm4Yx1M`,
	"icon": `IJPCC5DSecyS5rOsVD77`,
	"value": `SuUk8RDkVHsjYj6FazZq`
};
export default ___CSS_LOADER_EXPORT___;
