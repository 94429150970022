// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Elo9GqVYwjNLtL7n88wD{margin-top:20px;font-size:1em}.Elo9GqVYwjNLtL7n88wD .QeqXUFCNmQdmbbQSbv5V .wcke8szxeU2_laIJ5UTQ{float:left;color:#2ba31d}.Elo9GqVYwjNLtL7n88wD .QeqXUFCNmQdmbbQSbv5V .ukmS6BGCURECV021P8hg{color:#2ba31d;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Elo9GqVYwjNLtL7n88wD`,
	"printError": `QeqXUFCNmQdmbbQSbv5V`,
	"icon": `wcke8szxeU2_laIJ5UTQ`,
	"value": `ukmS6BGCURECV021P8hg`
};
export default ___CSS_LOADER_EXPORT___;
