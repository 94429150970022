// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hqru5ijSlrV4_Mjtk2rA{border:1px solid #f45d48;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;width:164px;background-color:#f45d48;color:var(--py-neutral-0);font-weight:600;line-height:40px;padding:0 20px}.Hqru5ijSlrV4_Mjtk2rA .nulybBUSG6IgOQ_W1j8T{position:absolute;top:1px;right:1px;width:76px;height:38px;display:flex;flex-direction:column;justify-content:center;align-items:center}.Hqru5ijSlrV4_Mjtk2rA .nulybBUSG6IgOQ_W1j8T img{height:24px}.Hqru5ijSlrV4_Mjtk2rA .vnIPDQwItQhdLDP_jCGA{position:absolute;top:1px;left:1px;width:90px;height:38px;display:flex;flex-direction:column;justify-content:center;align-items:center}.Hqru5ijSlrV4_Mjtk2rA:hover{background-color:#f34830;border-color:#e2280e}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Hqru5ijSlrV4_Mjtk2rA`,
	"iconWrapper": `nulybBUSG6IgOQ_W1j8T`,
	"label": `vnIPDQwItQhdLDP_jCGA`
};
export default ___CSS_LOADER_EXPORT___;
