// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g3c3Ll146oBmK_kKeriL{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#d83b01;color:var(--py-neutral-0);font-weight:600;line-height:40px}.g3c3Ll146oBmK_kKeriL .VCWAVFww1okfoad280lQ{position:absolute;top:1px;left:1px;width:38px;height:38px;background-color:var(--py-neutral-0);border-radius:1px}.g3c3Ll146oBmK_kKeriL .VCWAVFww1okfoad280lQ img{position:absolute;top:7px;left:7px;width:24px;height:24px}.g3c3Ll146oBmK_kKeriL .fZBUOmZEYEpu9OE7pox1{margin-left:50px}.g3c3Ll146oBmK_kKeriL:hover{background-color:#fe4704}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `g3c3Ll146oBmK_kKeriL`,
	"iconWrapper": `VCWAVFww1okfoad280lQ`,
	"label": `fZBUOmZEYEpu9OE7pox1`
};
export default ___CSS_LOADER_EXPORT___;
